$btn-bg-color: #ff0861;
$btn-padding: 0.4rem 1rem;
$btn-radius: 1rem;
$btn-font-size: 0.7rem;
$white-color: #fff;

.add-btn {
    border: 1px solid #ff0861 !important;
    color: #000 !important;
    text-transform: unset !important;

    &:hover {
        background-color: $btn-bg-color !important;
        color: $white-color !important;
    }

    outline: 0;
    border: 0px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.4rem 1rem;
    border-radius: 20px !important;
    font-size: 0.7rem;
}

.download-btn {
    background: $btn-bg-color !important;
    padding: $btn-padding !important;
    border-radius: $btn-radius !important;
    font-size: $btn-font-size !important;
    box-shadow: unset !important;
    text-transform: unset !important;
}

.loginbox button {
    font-size: 12px;
    width: 100%;
    border-radius: 2.5rem;
    text-transform: none;
    margin-top: 15px;
}

.retail-btn {
    border: 1px solid #9568FF !important;
    color: #9568FF !important;
    border-radius: 0.75rem !important;
    font-size: 0.7rem !important;
    text-transform: unset !important;
    padding: 5px 15px !important;
    background: $white-color;
    &:hover {
        color: $white-color !important;
        background: #9568FF !important;
    }
    
}

button.active{
    color: $white-color !important;
    background: #9568FF !important;
}

.btn-upload{
    background-color: #9568ff !important;
    color: $white-color !important;
    padding: 12px !important;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
    width: 150px;
    text-align: center;
    box-shadow: unset !important;
    text-transform: unset !important;
}

.location-select{
    color: #99abba !important;
    font-size: 0.785rem !important;
    border-radius: 2.5rem !important;
}

.pos-btns {
    button {
        display: flex;
        align-items: center;
          border-radius: 20px !important;
        img {
            width: 12px;
            margin-right: 2px;
        }
    }

    button:hover {
        img {
            filter: brightness(50);
        }
    }
}

.pos-orders-btns{
    gap: 20px;
    button{
        width: 90%;
        
    }
}

.btn-submit{
    input{
    cursor: pointer !important;
    }
}

.login-btn{
    cursor: pointer;
}

.add-cart-btn{
    background: #9568ff !important;
    max-width: 120px;
    width: 100%;
    color: #fff !important;
    box-shadow: unset !important;
}